import React, { Fragment, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import feather from 'feather-icons';
import AsyncCreatableSelect from 'react-select/async-creatable';

import Expander from '../Expander/Expander';
import FilterGroup_v2 from '../FilterGroup/FilterGroup_v2';
import { getCompany, getLocation, getSkills} from '../../api/filter/filter.api';
import { colourStyles_v2 } from './styles/FilterJob_v2.styles';
import FilterBy from './FilterBy';

const experienceOptions = [
  { label: '< 1 Year', value: '0-0' },
  { label: '1-2 Year', value: '1-2' },
  { label: '2-5 Year', value: '2-5' },
  { label: '5-10 Year', value: '5-10' },
];

const initialState = [
  { key: 'experienceYears', label: 'Experience Year' },
  { key: 'companyNames', label: 'Company' },
  { key: 'skills', label: 'Skills' },
  { key: 'locations', label: 'Location' },
];

const FilterJob_v2 = ({
  filterStack,
  setStackFilter,
  handleSearch,
  setActivePage = () => {},
  handleReset,
}) => {
  useEffect(() => {
    feather.replace();
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setActivePage(0);
    handleSearch();
  };

  const handleResetFilters = () => {
    if (handleReset && typeof handleReset === 'function') {
      setStackFilter({
        companyNames: [],
        schools: [],
        skills: [],
        keywords: [],
      });
      handleReset();
    } else {
      resetFilterStack();
    }
  };

  const resetFilterStack = () => {
    window.location.replace(`/job_search`);
  };

  const handleFilter = (event, key) => {
    setStackFilter({ ...filterStack, [key]: event });
  };

  const handleKeyPress = async (inputValue, callback, key) => {
    let arrayOptions = [];

    if (key === 'companyNames') {
      arrayOptions = await getCompany(inputValue);
    }
    if (key === 'experienceYears') {
      arrayOptions = experienceOptions;
    }
    if (key === 'skills') {
      arrayOptions = await getSkills(inputValue);
    }
    if (key === 'locations') {
      arrayOptions = await getLocation(inputValue);
    }
    callback(arrayOptions);
  };

  const handleCreate = (event, key) => {
    const newItem = { value: event, label: event };
    const cloneObject = filterStack[key];
    cloneObject.push(newItem);
    setStackFilter({ ...filterStack, [key]: [...cloneObject] });
  };

  function activeFilterCount(filters) {
    return Object.keys(filters)
      .map((key) => {
        if (Array.isArray(filterStack[key]))
          return filterStack[key].length > 0 ? 1 : 0;
        else if (typeof filterStack[key] === 'boolean')
          return filterStack[key] ? 1 : 0;
        else return 0;
      })
      .reduce((acc, current) => acc + current, 0);
  }

  return (
    <FilterGroup_v2
      filterCount={activeFilterCount(filterStack)}
      experienceYearsCount={filterStack.experienceYears}
    >
      {initialState.map(({ key, label }) => (
        <Fragment key={key}>
         <h5 className="medium-heading mb-15 mt-20">{label}</h5>
          {/* <Expander
            label={label}
            expanded={filterStack[key] && filterStack[key].length > 0}
          > */}
            {/* {key === 'skills' && (
              <>
                <FilterBy setStackFilter={setStackFilter} filterStack={filterStack}/>
              </>
            )} */}
            {key === 'experienceYears' ? (
                <AsyncCreatableSelect
                isMulti
                createOptionPosition="first"
                formatCreateLabel={() => `Select Any`}
                styles={colourStyles_v2}
                value={filterStack[key]}
                onChange={(event) => handleFilter(event, key)}
                loadOptions={(inputValue, callback) =>
                  handleKeyPress(inputValue, callback, key)
                }
                menuPlacement="auto"
                fmaxMenuHeight={30}
                onCreateOption={(event) => {
                  //handleCreate(event, key);
                }}
                menuPortalTarget={document.querySelector('body')}
                defaultOptions={key === "experienceYears" ? experienceOptions : false}
                placeholder="Select experience year"
              />
            ) : (
              <AsyncCreatableSelect
                isMulti
                createOptionPosition="first"
                styles={colourStyles_v2}
                value={filterStack[key]}
                onChange={(event) => handleFilter(event, key)}
                loadOptions={async(inputValue, callback) =>{
                  await handleKeyPress(inputValue, callback, key)
                }}
                menuPlacement="auto"
                fmaxMenuHeight={30}
                onCreateOption={(event) => {
                  handleCreate(event, key);
                }}
                menuPortalTarget={document.querySelector('body')}
                placeholder="Start typing to search or create..."
                defaultOptions={false}
              />
            )}
          {/* </Expander> */}
        </Fragment>
      ))}

      <Button className={' btn btn-default ml-30'} onClick={handleSubmit} style={{ background: '#005999',color: 'white' }}>
        Apply
      </Button>
      <Button className={' btn btn-default ml-30'} onClick={handleResetFilters} style={{ background: '#005999',color: 'white' }}>
        Reset
      </Button>
    </FilterGroup_v2>
  );
};

export default FilterJob_v2;
