import React, { useEffect, useState } from 'react'
import { DisplayPagination } from './styles/AdminDashboard.styled'
import DataTable from 'react-data-table-component-footer'
import moment from 'moment'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import P from './shared/P'
import Card from './shared/Card'
import { Col, Row, Spinner, Modal } from 'react-bootstrap'
import DateRangePicker from '../../common/DateRangePicker/DateRangePicker'
import styles from './styles/JobsStatusPage.module.scss'
import { CSVLink } from 'react-csv'
import   './styles/DataTableCustom.scss'
import   './styles/SubmissionReport.scss'
import Select from 'react-select'
import { capitalize } from '../../../utils'


const BeautifyJson = ({ data }) => {
  // Function to escape newlines and other special characters for better display
  const formatText = (text) => {
    return text
      .replace(/\n/g, "<br/>") // Replace newlines with <br/> for HTML rendering
      .replace(/ /g, "\u00A0"); // Replace spaces with non-breaking spaces
  };

  // Function to recursively render JSON with indentation
  const renderJson = (json, indentLevel = 0) => {
    if (typeof json === "object" && json !== null) {
      if (Array.isArray(json)) {
        return (
          <div style={{ paddingLeft: `${indentLevel * 20}px` }}>
            {json.map((item, index) => (
              <div key={index} style={{ marginBottom: "5px" }}>
                <strong>[{index}]</strong>: {renderJson(item, indentLevel + 1)}
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div style={{ paddingLeft: `${indentLevel * 20}px` }}>
            {Object.entries(json).map(([key, value]) => (
              <div key={key} style={{ marginBottom: "5px" }}>
                <strong>{key}:</strong>{" "}
                {typeof value === "object" && value !== null
                  ? renderJson(value, indentLevel + 1)
                  : <span dangerouslySetInnerHTML={{ __html: formatText(value?.toString()) }} />}
              </div>
            ))}
          </div>
        );
      }
    } else {
      return <span style={{ color: "darkblue" }}>{json?.toString()}</span>;
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f4f4f4",
        padding: "15px",
        borderRadius: "5px",
        fontFamily: "monospace",
        fontSize: "14px",
        overflowX: "auto",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
      }}
    >
      {renderJson(data)}
    </div>
  );
}
const SubmissionReportPage = ({recruiter_list, job_list}) => {
  const [hiredCandidate, setHiredCandidate] = useState([])
  let hire_org_type = sessionStorage.getItem("sub_hire_org_type")
  let recruiterStatus_recruiter = sessionStorage.getItem("sub_recruiterStatus_recruiter")
  const urlRecruiterId = sessionStorage.getItem("sub_recruiter")
  let jobStatus_job = sessionStorage.getItem("sub_jobStatus_job")
  const jobId = sessionStorage.getItem("sub_job")
  const [activePage, setActivePage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(25)
  const [totalCount,setTotalCount] = useState()
  const [dateRange, setDateRange] = useState({startDate: moment().startOf('month').toDate() ,endDate: moment().toDate()})
  const [loading, setLoder] = useState(true)
  const [recruiter,setRecruiter] = useState([{value: 'all', label: 'All'}, ...recruiter_list])
  const [exportDatas, setExportDatas] = useState([])
  const csvExportRef = React.useRef();
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [startRecord, setStartRecord] = useState(0)
  const [showScoreModal, setShowScoreModal] = useState(false)
  const [showResumeParseModal, setShowResumeParseModal] = useState(false)
  const [scoreData, setScoreData] = useState({})
  const [currentCounts,setCurrentCounts] = useState(0)
  const [jobs,setJobs] = useState([{value: 'all', label: 'All'},...job_list])
  const [selectedJob,setSelectedJob] = useState(jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job).value : jobId ? jobId : '')
  const [selected,setSelected] = useState((jobId)? job_list.filter((option) => option.value == jobId) : jobStatus_job && jobStatus_job != "null" ? JSON.parse(jobStatus_job) : {value: 'all', label: 'All'})
  const [selectedRecruiter,setSelectedRecruiter] = useState((urlRecruiterId) ? urlRecruiterId : recruiterStatus_recruiter && recruiterStatus_recruiter != "null" ? JSON.parse(recruiterStatus_recruiter).value : '')
  const [defaultselected,setdefaultselected] = useState(hire_org_type && hire_org_type != "null" ? JSON.parse(hire_org_type) : {value: 'all', label: 'All'})
  const stages = {lead: 'Lead', applicant: 'Applicant', recruitor_screen: 'Recruiter Screened', submitted: 'Submitted', first_interview: 'First Interview', second_interview: 'Second Interview',onhold: 'On Hold', offer: 'Offer', reject: 'Archived', hired: 'Hired'}
  const [stageLists, setStageLists] = useState([{value: 'all', label: 'All'},{value: 'applicant', label: 'Applicant'},{value: 'recruitor_screen', label: 'Recruiter Screened'},{value: 'submitted', label: 'Submitted'}, {value: 'first_interview', label: 'First Interview'}, {value: 'second_interview', label: 'Second Interview'},{value: 'onhold', label: 'On Hold'}, {value: 'offer', label: 'Offer'}, {value: 'hired', label: 'Hired'}, {value: 'reject', label: 'Archived'}])
  const [selectedStage, setSelectedStage] = useState('all')
  const [defaultStageselected, setdefaultStageselected] = useState({value: 'all', label: 'All'})
  const handleDate = (date) => {
    setDateRange(date)
  }

  const fetchData = async () => {
    setLoder(true)
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/submission_report.json'
    const response = await makeRequest(
      `${url}?page=${activePage}&start_date=${start_date}&end_date=${end_date}&recruiter=${selectedRecruiter}&sortField=${sortField}&job=${selectedJob}&sortDirection=${sortDirection}&stage=${selectedStage}`,
      'get',
      {}
    )
    let sr_no = (activePage -1) * perPage
    setPageCount(response.data.total_pages)
    setTotalCount(response.data.total_count)
    setStartRecord((activePage - 1) * response.data.per_page)
    setCurrentCounts(response.data.current_counts)
    setHiredCandidate(response.data.candidates.map((candidate) => (sr_no = sr_no + 1,{
        id: sr_no,
        candidate_name: capitalize(candidate?.candidate_name),
        email_address: candidate?.email_address,
        job_name: candidate?.job_name,
        recruiterName: '',
        reachout_time: (candidate?.reachout_time != null) ? moment(candidate?.reachout_time).format('MMM DD, YYYY') : '-',
        submission_type: capitalize(stages[candidate?.submission_type]),
        match_score: candidate?.match_score,
        source: (candidate?.source == 'linkedin_auto') ? 'Linkedin Applicant' : capitalize(candidate?.source),
        job_owner: candidate?.job_owner,
        oragnization_name: candidate?.oragnization_name,
        am_name: candidate?.am_name,
        rank_score: candidate?.rank_score,
        highest_company: candidate?.highest_company,
        highest_school: candidate?.highest_school,
        company_ranked: candidate?.company_ranked,
        school_ranked: candidate?.school_ranked,
        latest_company: candidate?.latest_company,
        latest_school: candidate?.latest_school,
        move_by: candidate?.move_by,
        action: (candidate?.cv_url != null && candidate?.cv_url != '') ? <><a href={candidate?.cv_url} target='_blank' className='ml-1' title='Resume'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                        <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg>
                      </a>
                         <a href="javascript:void(0)" className='ml-1' title='Resume Parse JSON' onClick={()=> showResumeJson(candidate)}>
                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm8 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
                          <path d="M6 5h4v1H6zM6 7h4v1H6zM6 9h4v1H6z"/>
                        </svg>
                       </a></>
                      : ''
    })))
    setLoder(false)
  }

  const headers = [
    { label: 'Candidate Name', key: 'candidate_name' },
    { label: 'Candidate Email', key: 'email_address' },
    { label: 'Job Name', key: 'job_name' },
    { label: 'Current Stage', key: 'submission_type' },
    { label: 'Source', key: 'source' },
    { label: 'Outreach Date', key: 'reachout_time' },
    { label: 'Match Score', key: 'match_score' },
    { label: 'Ranking Score', key: 'rank_score' },
    { label: 'Highest Rank Company', key: 'highest_company' },
    { label: 'Highest Rank School', key: 'highest_school' },
    { label: 'Company Rank', key: 'company_ranked' },
    { label: 'School Rank', key: 'school_ranked' },
    { label: 'Latest Company', key: 'latest_school' },
    { label: 'Latest School', key: 'latest_company' },
];

  const exportDataMethod = async () => {
    let start_date = moment(dateRange.startDate).format('YYYY-MM-DD')
    let end_date =  moment(dateRange.endDate).format('YYYY-MM-DD')
    const url = '/admin/submission_report.json'
    const response = await makeRequest(
      `${url}?start_date=${start_date}&end_date=${end_date}&sortField=${sortField}&job=${selectedJob}&recruiter=${selectedRecruiter}&sortDirection=${sortDirection}`,
      'get',
      {}
    )
    setExportDatas(response?.data?.candidates.map((candidate,key)=> ({
        id: key,
        candidate_name: capitalize(candidate?.candidate_name),
        email_address: candidate?.email_address,
        job_name: candidate?.job_name,
        recruiterName: '',
        reachout_time: (candidate?.reachout_time != null) ? moment(candidate?.reachout_time).format('MMM DD, YYYY') : '-',
        submission_type: capitalize(stages[candidate?.submission_type]),
        match_score: candidate?.match_score,
        source: (candidate?.source == 'linkedin_auto') ? 'Linkedin Applicant' : candidate?.source,
        job_owner: candidate?.job_owner,
        oragnization_name: candidate?.oragnization_name,
        am_name: candidate?.am_name,
        move_by: candidate?.move_by,
        rank_score: candidate?.rank_score,
        highest_company: candidate?.highest_company,
        highest_school: candidate?.highest_school,
        company_ranked: candidate?.company_ranked,
        school_ranked: candidate?.school_ranked,
        latest_company: candidate?.latest_company,
        latest_school: candidate?.latest_school,
    })))
    csvExportRef?.current?.link.click();
  }

  const handleSort = async (column, sortDirection) => {
    if(column.sortField != undefined && sortDirection != undefined){
        setSortField(column.sortField)
        setSortDirection(sortDirection)
    }
  }

  const showScore = (candidate) => {
    setShowScoreModal(true)
    setScoreData(candidate)
}
  const showResumeJson = (candidate) => {
    setShowResumeParseModal(true)
    setScoreData(candidate)
  }

  useEffect(()=>{
    fetchData()
  },[dateRange,activePage,sortDirection,sortField, selectedRecruiter, selectedJob, selectedStage])

  
  const  columns= [
    {
        name: 'No.',
        selector: row => row.id,
        maxWidth: '4%',
        minWidth: '4%',
    },
    {
        name: 'Candidate Name',
        selector: row => row.candidate_name,
        cell: (row) => <div title={row.candidate_name}>{row.candidate_name}</div>,
        maxWidth: '8%',
        minWidth: '8%',
        wrap:true,
        sortable: true,
        sortField: 'candidate_name',
    },
    {
        name: 'Candidate Email',
        selector: row => row.email_address,
        cell: (row) => <div title={row.email_address}>{row.email_address}</div>,
        maxWidth: '9%',
        minWidth: '9%',
        wrap:true,
        sortable: true,
        sortField: 'email_address',
    },
    {
      name: 'Job Name',
      selector: row => row.job_name,
      cell: (row) => <div title={row.job_name}>{row.job_name} ({row?.job_owner})</div>,
      maxWidth: '10%',
      minWidth: '10%',
      wrap:true,
      sortable: true,
      sortField: 'job_name',
    },
    {
      name: 'Organization Name',
      selector: row => row.oragnization_name,
      cell: (row) => <div title={row.oragnization_name}>{row.oragnization_name}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'oragnization_name',
    },
    {
      name: 'AM Name',
      selector: row => row.am_name,
      cell: (row) => <div title={row.am_name}>{row.am_name}</div>,
      maxWidth: '9%',
      minWidth: '9%',
      wrap:true,
      sortable: true,
      sortField: 'am_name',
    },
    {
      name: 'Current Stage',
      cell: row => row.submission_type,
      cell: (row) => <div title={row.submission_type}>{row.submission_type}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'submission_type',
    },
    {
      name: 'Submitted By',
      cell: row => row.move_by,
      cell: (row) => <div title={row.move_by}>{row.move_by}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'move_by',
    },
    {
      name: 'Source',
      selector: row => row.source,
      cell: (row) => <div title={row.source}>{row.source}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'source',
    },
    {
      name: 'Outreach Date',
      selector: row => row.reachout_time,
      cell: (row) => <div title={row.reachout_time}>{row.reachout_time}</div>,
      maxWidth: '8%',
      minWidth: '8%',
      wrap:true,
      sortable: true,
      sortField: 'reachout_time',
    },
    {
        name: 'Match Score',
        selector: row => row.match_score,
        cell: (row) => <div title={row.match_score}>{row.match_score}</div>,
        maxWidth: '7%',
        minWidth: '7%',
        wrap:true,
        sortable: true,
        sortField: 'match_score',
    },
    {
        name: 'Ranking Score',
        selector: row => row.rank_score,
        cell: (row) => <a href="javascript::void(0)" title={row.rank_score} onClick={()=>showScore(row)}>{row.rank_score}</a>,
        maxWidth: '6%',
        minWidth: '6%',
        wrap:true,
        sortable: false,
        sortField: 'rank_score',
    },
    {
        name: 'Action',
        selector: row => row.action,
        maxWidth: '7%',
        minWidth: '7%',
        wrap:true,
        sortable: false,
    }
  ]

  const colourStyles = {
    control: styles => ({ ...styles, borderColor: 'silver', height: 'auto',backgroundColor: '#F6F7FC',minWidth:'16em',maxWidth:'16em',minHeight:'40px' })
  };

  
  return (
    <>
    <Card>
      <div
        className="d-flex justify-content-between align-items-center w-100"
      >
        <P size="28px" height="38px" color="#1D2447">
          Candidate Submission Report
        </P>
        <div>
          <button className={`ml-3 ${styles.exportCSVButton}`} onClick={()=>exportDataMethod()}>
              Export CSV
          </button>
        </div>
      </div>
      <CSVLink
        className='hidden'
        data={exportDatas} 
        headers={headers}
        filename={"candidate_submission_report.csv"}
        ref={csvExportRef}
      />
      <Row className="w-100" style={{ marginBottom: '10px' }}>
        <Col lg="12" md="12" sm="12" xs="12" className='d-flex justify-content-end p-0 filter_ui_fix'>
          <div style={{display: 'grid', marginLeft:'10px'}}>
              <label>
                  Job Name
              </label>
              <Select
                  defaultValue={selected}
                  options={jobs}
                  onChange={(event)=>{
                      setSelectedJob(event.value)
                      sessionStorage.setItem("sub_jobStatus_job", JSON.stringify(event))
                  }}
                  name="job_name"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid', marginLeft:'10px'}}>
              <label>
                  Stage
              </label>
              <Select
                  defaultValue={defaultStageselected}
                  options={stageLists}
                  onChange={(event)=>setSelectedStage(event.value)}
                  name="stage"
                  className={styles.colourStyles}
              />
          </div>
          <div style={{display: 'grid',marginLeft:'10px'}}>
            <label>
                Date Range
            </label>
            <DateRangePicker
                handleOnSubmit={handleDate}
                defaultDate={dateRange}
            />
          </div>
        </Col>
        <Col lg="12" md="12" sm="12" xs="12" className='d-flex align-items-end'>
            {totalCount > 0 &&
                <DisplayPagination>Displaying  {(activePage == 0)?1:startRecord+1} - {currentCounts > totalCount ? totalCount : currentCounts} of {totalCount}</DisplayPagination>
            }
        </Col>
      </Row>
      <DataTable
        title={false}
        persistTableHead={true}
        noDataComponent={<p className="text-center p-3">No record found</p>}
        columns={columns}
        data={hiredCandidate}
        progressPending={loading}
        progressComponent={ <P className="text-center p-3" size="18px" color="#6077f4">
            <Spinner className='spinner' animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </P>}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={totalCount}
        paginationPerPage={25}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        onSort={handleSort}
        sortServer
        responsive={true}
        onChangePage={page => {
          setActivePage(page)
        }}
    />
    </Card>
    <Modal
    show={showResumeParseModal}
    onHide={() => setShowResumeParseModal(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    scrollable
    centered
>    

    <Modal.Header closeButton >
        <Modal.Title>Resume Parse JSON</Modal.Title>
    </Modal.Header>
    <Modal.Body >
          <div
            style={{
              backgroundColor: "#f9f9f9",
              padding: "15px",
              borderRadius: "5px",
              fontFamily: "monospace",
              fontSize: "14px",
              overflowX: "auto",
            }}
          >
              <BeautifyJson data={scoreData?.parse_resume_json} />
          </div>
    </Modal.Body>
    </Modal>
    <Modal
    show={showScoreModal}
    onHide={() => setShowScoreModal(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    scrollable
    centered
>
    <Modal.Body >
            <div >
                <div className='scrore-header mt-2'>
                    <p size="16px" height="27px" className='p-score'>
                        Profile Score: <strong>{scoreData?.rank_score}</strong>
                    </p>
                </div>
                
                <Row>
                    <Col xs={4} md={4} sm={4} lg={4} className='pr-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                School Ranked
                            </p>
                        </div>
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}  className='pl-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                {scoreData?.school_ranked}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={4} sm={4} lg={4} className='pr-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                Company Ranked
                            </p>
                        </div>
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}  className='pl-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                {scoreData?.company_ranked}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={4} sm={4} lg={4} className='pr-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                Highest School
                            </p>
                        </div>
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}  className='pl-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                {scoreData?.highest_school}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={4} sm={4} lg={4} className='pr-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                Highest Company
                            </p>
                        </div>
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}  className='pl-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                {scoreData?.highest_company}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={4} sm={4} lg={4} className='pr-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                Latest School
                            </p>
                        </div>
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}  className='pl-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                {scoreData?.latest_school}
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} md={4} sm={4} lg={4} className='pr-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                Latest Company
                            </p>
                        </div>
                    </Col>
                    <Col xs={8} md={8} sm={8} lg={8}  className='pl-0'>
                        <div className='score-body'>
                            <p className='score-body-p'>
                                {scoreData?.latest_company}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
    </Modal.Body>
</Modal>
</>
  )
}

export default SubmissionReportPage