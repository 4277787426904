import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import styles from './styles/Navbar.module.scss'
import ProfileAvatarEmployer from '../../common/ProfileAvatar/ProfileAvatarEmployer'
import styleNew from './styles/NavbarEmployer.scss'
import '../../../../assets/v2/css/main.css'
// import avatarImg from '../../../../assets/v2/imgs/avatar/ava_1.png'
import cardinalImg from '../../../../assets/images/tab_icon.png'
import discoverImg from '../../../../assets/v2/imgs/discover.png'
import plusImg from '../../../../assets/v2/imgs/add.png'
import avatarImage from '../../../../assets/images/placeholder.png'
import Util from '../../../utils/util'
import { getCommunitiesList } from '../../api/recruiter.api'
import LogoWithoutText from '../../../../assets/images/Cardinal.png'

const NavbarCommunityAI = ({
    userId,
    isTalent,
    isEmployer,
    avatar_url,
    organization,
    currentUser,
    memberOrganization,
    base_url,
    host_url,
    active_community,
    community_id,
    is_groups_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const [communitiesList, setCommunitiesList] = useState([])
    const [communityExists, setCommunityExists] = useState(false);
    const [communityType, setCommunityType] = useState('');
    const homeURl = (currentUser.role == 'talent') ? '/talent_home' : '/jobs';
    var SideName = (is_groups_url)?'Cardinal Groups':'CardinalTalent';

    const fetchCommunities = async () => {
        try {
            const response = await getCommunitiesList(community_id);
            setCommunitiesList(
                response.communities.map((community) => ({
                    id: community.id,
                    name: community.name,
                    avatar_image: community.avatar_image,
                }))
            )
            setCommunityType(response.community_type)
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchCommunities()
    }, [])

    useEffect(() => {
        const communityExists = communitiesList.some(item => item.id == community_id);
        setCommunityExists(communityExists);
    }, [communitiesList, community_id]);

    return (
        <>
             <Navbar expand="lg" sticky="top" style={{zIndex: 1040 }}>
                <Navbar.Brand href="/communities">
                    <img
                        alt=""
                        src={LogoWithoutText}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span>{SideName}</span>{' '}
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                       <Nav.Link href="https://cardinaltalent.ai">Employers</Nav.Link>
                        <Nav.Link href="/talent_home">Talent</Nav.Link>
                        <Nav.Link href="/welcome/recruiter">Recruiters</Nav.Link>
                        <Nav.Link href="https://app.cardinaltalent.ai/communities" className='activeLinkColorTop' style={{color: '#000'}}>Communities</Nav.Link>
                    </Nav>
                    <ProfileAvatarEmployer
                        profileAvatar={avatar_url}
                        userId={userId}
                        organization_id={organization ? organization.id : ''}
                        isEmployer={isEmployer}
                        currentUser={currentUser}
                        userBelongsToCT={false}
                        subOrgBelongsToCT={false}
                        isTalent={isTalent}
                    />
                </Navbar.Collapse>
            </Navbar>
            <Navbar expand="lg" variant="dark" className="text-white dark-blue-nav" sticky="top">
                

                <Navbar.Collapse id="basic-navbar-nav-second" className="justify-content-end">
                    <Nav>
                        {/* <Nav.Link href="/communities"
                                className={`${styles.navbarLink} ${currentPathname === 'communities' ||
                                        currentPathname === '/communities'
                                        ? 'activeLinkColor'
                                        : 'linkMenu'
                                    }`}
                            >
                                Communities
                        </Nav.Link> */}
                        {communitiesList && communitiesList.length > 0 && 
                            <NavDropdown title="My Community" id="basic-nav-dropdown-task" style={{marginLeft: '20px'}}>
                                    {communitiesList && communitiesList.map((community, index) => {
                                        return <NavDropdown.Item key={index} href={"/communities/" + community.id}  className={`navbarLinkCust linkMenuProfile`} 
                                            >{community.name}</NavDropdown.Item>
                                    })}
                            </NavDropdown>
                        }
                        {(community_id > 0 && (communityExists || communityType == 'public')) && (
                        <>
                            
                            <Nav.Link href={`${'/communities/' + community_id + '/classroom'}`}
                                    className={`${styles.navbarLink} ${currentPathname === '/classroom' ||
                                            currentPathname === '/classroom' || currentPathname.includes('classroom') == true
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                >
                                    Classroom
                            </Nav.Link>
                            {(communityExists) && (
                                <Nav.Link href={`${'/communities/' + community_id + '/members'}`}
                                        className={`${styles.navbarLink} ${currentPathname === '/members' ||
                                                currentPathname === '/members' || currentPathname.includes('members') == true
                                                ? 'activeLinkColor'
                                                : 'linkMenu'
                                            }`}
                                    >
                                        Members
                                </Nav.Link>
                            )}
                            <Nav.Link href={`${'/communities/' + community_id + '/about'}`}
                                    className={`${styles.navbarLink} ${currentPathname === '/about' ||
                                            currentPathname === '/about' || currentPathname.includes('about') == true
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                >
                                    About
                            </Nav.Link>
                        </>
                        )}
                        {(community_id == 0) &&
                        <>
                            <Nav.Link href="/communities/v5"
                                    className={`${styles.navbarLink} ${currentPathname === '/communities/v5' ||
                                            currentPathname === '/communities/v5'
                                            ? 'activeLinkColor'
                                            : 'linkMenu'
                                        }`}
                                >
                                    Companies
                            </Nav.Link>
                        </>
                        }
                       <Nav.Link href={homeURl}
                            className={`${styles.navbarLink} ${currentPathname === homeURl ||
                                    currentPathname === homeURl
                                    ? 'activeLinkColor'
                                    : 'linkMenu'
                                }`}
                        >
                             Jobs
                       </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default NavbarCommunityAI
