import React, { useState, useEffect} from 'react'
import { Formik, Field,  ErrorMessage,Form as FormF, useField } from 'formik'
import { Form } from "react-bootstrap";
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
import { Spinner, Button, Alert } from 'react-bootstrap'
import styles from '../../SignupPage/styles/Signup.module.scss'
import '../../../../../assets/v2/css/main.css'
import FileButton from '../../../common/FileButton/FileButtonV2'

function SignUpFields({ userRole, token, candidateDetails}) {
  const [candidateInfo, setCandidateInfo] = useState(candidateDetails);
  const queryParams = new URLSearchParams(window.location.search)
  document.cookie = "token=" + token
  const cookie = document.cookie
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [resume, setResume] = useState(null)
  const [resumeError, setResumeError] = useState(null)

  useEffect(() => {
    if (candidateInfo?.candidate_name == "guest_user") {
      setCandidateInfo((prevInfo) => ({
        ...prevInfo,
        candidate_name: '',
        candidate_email: '',
      }));
    }
  }, [candidateInfo?.candidate_name]);

  const validationSchema = Yup.object({
    email: Yup.string({ userRole })
      .email()
      .required('Email is required')
      .test(
        'email-unique',
        'This email is already in use',
        async function (value) {
          const res = await axios.get(
            `/users/exists?email=${encodeURIComponent(value)}`
          )
          return !res.data.user_exists
        }
      )
      .test(
        'email-fake',
        userRole == 'employer' ? 'Please enter your work email. Example: name@companyname.com' : 'Please enter a valid domain',
        async function (value) {
          const domain = await axios.get(
            `/users/blocked_email_domain.json?email=${(value)}&role=${(userRole)}`
          )
          console.log(domain.data.email_blocked_domain)
          return !domain.data.email_blocked_domain
        }
      ),
      // .matches(
      //   /^((([a-zA-Z0-9]|\d|[!#\$%&'\-\/=\?\^_`{\|}~])+(\.([a-zA-Z0-9]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])+|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))?$/,
      //   // /^((([a-zA-Z]|\d|[!#\$%&'\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,
      //   'Restricted character found in the email address'
      // ),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,25}$/,
        'Password should have 8 to 25 characters, must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character'
      ),
    agreeToTermsAndPolicy: Yup.boolean()
    .oneOf(
      [true],
      'Please accept the Terms and Conditions & Privacy Policy'
    ),
    agreeToBusiness: userRole == 'recruiter' ? Yup.boolean().oneOf(
      [true],
      'Please accept the Business Terms.'
    ) : Yup.boolean(),
    first_name: Yup.string()
      .required('First Name is required')
      .max(50, 'Must be exactly 50 characters')
      .test(
          'first name alphabets only',
          'First Name can only contain alphabet characters and one space in between if there are two words',
          function (value) {
              const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
              return regex.test(value)
          }
      ),
    last_name: Yup.string()
      .required('Last Name is required')
      .max(50, 'Must be exactly 50 characters')
      .test(
          'last name alphabets only',
          'Last Name can only contain alphabet characters and one space in between if there are two words',
          function (value) {
              const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
              return regex.test(value)
          }
      ),
  })

  const handleSubmit = async (user) => {
    const payload = new FormData()
    const url = `/ai_interviews/signup/${userRole}`
    if (!_.isEmpty(userRole)) {
      payload.append('user[role]', userRole)
      payload.append('user[email]', user.email)
      payload.append('user[password]', user.password)
      payload.append('user[password_confirmation]', user.password)
      payload.append('user[first_name]', user.first_name)
      payload.append('user[last_name]', user.last_name)
      payload.append('user[interview_token]', token)
      const CSRF_Token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
      setLoading(true)
      console.log(url)
      await axios
        .post(url, payload, {
          headers: {
            'content-type': 'application/json',
            'X-CSRF-Token': CSRF_Token,
          },
          userRole,
        })
        .then((res) => {
          const responce = res.data
          if (responce.success) {
            setResponse({ messageType: 'success', message: responce.msg })
            window.location.href = '/ai_interviews/invite/'+token
          } else {
            setResponse({ messageType: 'danger', message: responce.msg })
          }
          setLoading(false)
        })
        .catch((e) => {
          setResponse({ messageType: 'success', message: e })
          setLoading(false)
        })
    } else {
      setResponse({ messageType: 'danger', message: 'Please Select Role' })
    }
  }

  return (
    <div>
      {!_.isEmpty(response) && (
        <Alert
          variant={response.messageType}
          onClose={() => setResponse({})}
          style={{ fontSize: '15px', width: '27rem' }}
          dismissible
          className="candidate-info-close"
        >
          <span className={styles.alertInfo}> {response.message} </span>
        </Alert>
      )}
      
      <Formik
        enableReinitialize
        validateOnChange={true}
        validateOnBlur={false}
        initialValues={{
          first_name: candidateInfo?.candidate_name?.split(' ')[0],
          last_name: candidateInfo?.candidate_name?.split(' ')[1],
          email: candidateInfo?.candidate_email,
          password: '',
          agreeToTermsAndPolicy: false,
          agreeToBusiness: false,
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {}
          // if (!resume) {
          //   errors.resume = 'Resume is required'
          //   setResumeError(errors.resume)
          // }
          return errors
        }}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, touched, errors, values}) => (
        <FormF className={styles.signForm} onSubmit={handleSubmit}>
          <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <svg width="24" height="24" className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="rgba(0,123,255,1)" viewBox="0 0 20 16">
                        <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                        <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                    </svg>
                </span>
            </div>
            <Form.Control type="email" className="form-control" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder="Email Address"/>
          </div>
          {touched.email && errors.email ? (
              <span className="text-danger">{errors.email}</span>
            ) : null}
          <div className="input-group mt-3">
              <div className="input-group-prepend">
                  <span className="input-group-text">
                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                          fill="rgba(0,123,255,1)">
                          <path
                              d="M19 10H20C20.5523 10 21 10.4477 21 11V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11C3 10.4477 3.44772 10 4 10H5V9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9V10ZM5 12V20H19V12H5ZM11 14H13V18H11V14ZM17 10V9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9V10H17Z">
                          </path>
                      </svg>
                  </span>
              </div>
              <Form.Control type="password" name="password" onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="Password" />
          </div>
          {touched.password && errors.password ? (
              <span className="text-danger">{errors.password}</span>
            ) : null}
          <Form.Control type="text" name="first_name" value={values.first_name} onChange={handleChange} onBlur={handleBlur} className="form-control mt-3" style={{width: '100%'}} placeholder="First Name" />
          {touched.first_name && errors.first_name ? (
              <span className="text-danger">{errors.first_name}</span>
            ) : null}
          <Form.Control type="text" name="last_name" value={values.last_name} onChange={handleChange} onBlur={handleBlur} className="form-control mt-3" style={{width: '100%'}} placeholder="Last Name" />
          {touched.last_name && errors.last_name ? (
              <span className="text-danger">{errors.last_name}</span>
            ) : null}  
          {/* <FileButton
            label="Add Resume"
            type="button"
            width={321}
            file={resume}
            resumeError={
                resumeError
            }
            setResumeError={
                setResumeError
            }
            getFile={(file) =>
                setResume(file)
            }
          /> */}
          {userRole == 'recruiter' && (
          <div className="second-action-container" style={{display: "flex",justifyContent: "space-between",alignItems: "center",marginTop: 10}}>
            <div className="remember-me-container d-flex">
              {/* <input type="checkbox" name="remember" className="sonara-checkbox checkbox1" id="id_remember"></input> */}
              <Field type="checkbox" name="agreeToBusiness" className="sonara-checkbox checkbox1" id="id_remember"/>
              <label htmlFor="id_remember" style={{ fontWeight: 500 }}>
                <p className="auth-secondary-text" style={{ margin: 0, color: "#666" }}>
                  By creating an account, you are agreeing to our
                  <a href='/marketing_terms' target='_blank' style={{ textDecoration: "underline" }}>
                    {" "}
                    Marketing Terms
                  </a>{" "}
                </p>
              </label>
            </div> 
          </div>
          )}
          <ErrorMessage name="agreeToBusiness" render={msg => <span className="text-danger">{msg}</span>} />   
          <div className="second-action-container" style={{display: "flex",justifyContent: "space-between",alignItems: "center",marginTop: 10}}>
            <div className="remember-me-container d-flex">
              {/* <input type="checkbox" name="remember" className="sonara-checkbox checkbox1" id="id_remember"></input> */}
              <Field type="checkbox" name="agreeToTermsAndPolicy" className="sonara-checkbox checkbox1" id="id_remember"/>
              <label htmlFor="id_remember" style={{ fontWeight: 500 }}>
                <p className="auth-secondary-text" style={{ margin: 0, color: "#666" }}>
                  By creating an account, you are agreeing to our
                  <a href='/terms_of_service' target='_blank' style={{ textDecoration: "underline" }}>
                    {" "}
                    Terms &amp; Conditions
                  </a>{" "}
                  and{" "}
                  <a href='/privacy_policy' target='_blank' style={{ textDecoration: "underline" }}>
                    Privacy Policy
                  </a>
                </p>
              </label>
            </div> 
          </div>
          <ErrorMessage name="agreeToTermsAndPolicy" render={msg => <span className="text-danger">{msg}</span>} />   
          <div className="text-center">
            <button type="submit" disabled={loading} className="btn-block btn btn-primary btn-width">{loading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                'Sign Up'
              )}
            </button>
          </div>
        </FormF>
        )}
      </Formik>
      
    </div>
  )
}

export default SignUpFields
