import React, { useState, useEffect } from 'react'
import '../styles/AiInterview.scss'

const Public = ({}) => {

    return (
        <>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
                <div className='invalid-interview-div'>
                    <h3 style={{ fontSize: '18px', color: '#005999' }}>Welcome to CardinalTalent AI Interview</h3>
                    <p style={{ fontSize: '18px', marginTop: '20px' }}>Unfortunately, it looks like the invitation link you used is invalid.</p>
                    <div className='d-flex'>
                        <a href="/users/sign_in" className='btn btn-primary ai-btn-primary'>Go to Home Page</a>
                    </div>
                </div>
            </div>
        </>
    );

}
export default Public