import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import ProfileAvatarEmployer from '../../common/ProfileAvatar/ProfileAvatarEmployer'
import styles from './styles/Navbar.module.scss'
import styleNew from './styles/NavbarEmployer.scss'
import LogoWithoutText from '../../../../assets/images/Cardinal.png'


const NavbarTalentAI = ({
    userId,
    isTalent,
    isEmployer,
    avatar_url,
    organization,
    currentUser,
    memberOrganization,
    base_url,
    host_url,
    is_groups_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    var SideName = (is_groups_url)?'Cardinal Groups':'CardinalTalent';
    const userBelongsToCT = organization?.name === window.ch_const.ct_org_name
    const subOrgBelongsToCT =
        memberOrganization?.name === window.ch_const.ct_org_name

    const authLinks = (
        <>
                <Nav.Link
                    href='/talent_home'
                    className={`${styles.navbarLink} ${currentPathname === 'talent_home' ||
                            currentPathname === '/talent_home' || currentPathname.includes('talent_home') == true
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Dashboard
                </Nav.Link>
                <Nav.Link
                    href='/ai_interviews/all'
                    className={`${styles.navbarLink} ${currentPathname === 'ai_interviews/all' ||
                            currentPathname === '/ai_interviews/all' || currentPathname.includes('ai_interviews') == true
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Interviews
                </Nav.Link>
           
                <Nav.Link
                    href="/job_search"
                    className={`${styles.navbarLink} ${currentPathname === '/job_search' ||
                            currentPathname === '/job_search'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Jobs
                </Nav.Link>
          
                <Nav.Link
                    href="/search_employer"
                    className={`${styles.navbarLink} ${currentPathname === '/search_employer'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Search Decision Makers
                </Nav.Link>
            
                <Nav.Link
                    href="/my_networks"
                    className={`${styles.navbarLink} ${currentPathname === '/my_networks'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    My Network
                </Nav.Link>
            
                <Nav.Link
                    href="/network_conversations"
                    className={`${styles.navbarLink} ${currentPathname === '/network_conversations'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                    Messaging
                </Nav.Link>

                <Nav.Link
                    href="/account/invite_your_friend"
                    className={`${styles.navbarLink} ${currentPathname === '/account/invite_your_friend'
                            ? 'activeLinkColor'
                            : 'linkMenu'
                        }`}
                >
                     Invite Your Network
                </Nav.Link>
           
        </>
    )
    
    
    return (
        <>
            <Navbar expand="lg" sticky="top" style={{zIndex: 1040 }}>
                <Navbar.Brand href="/talent_home">
                    <img
                        alt=""
                        src={LogoWithoutText}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    { (is_groups_url) ? 
                        <>
                        <span>Cardinal Groups</span>{' '}
                       </>:
                        <><span>CardinalTalent</span>{' '}
                        </>
                    }
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="https://cardinaltalent.ai">Employers</Nav.Link>
                        <Nav.Link href="/talent_home" className='activeLinkColorTop' style={{color: '#000'}}>Talent</Nav.Link>
                        <Nav.Link href="/welcome/recruiter">Recruiters</Nav.Link>
                        <Nav.Link href="https://app.cardinaltalent.ai/communities">Communities</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                     <ProfileAvatarEmployer
                        profileAvatar={avatar_url}
                        userId={userId}
                        organization_id={organization ? organization.id : ''}
                        isEmployer={isEmployer}
                        currentUser={currentUser}
                        userBelongsToCT={userBelongsToCT}
                        subOrgBelongsToCT={subOrgBelongsToCT}
                        isTalent={isTalent}
                    />
            </Navbar>

            <Navbar expand="lg" variant="dark" className="text-white dark-blue-nav" sticky="top">
                

                <Navbar.Collapse id="basic-navbar-nav-second" className="justify-content-end">
                    <Nav>
                        {userId && isTalent && authLinks}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

        </>
    )
}

export default NavbarTalentAI
