import React, { useState, useEffect, useRef } from 'react'
import '../styles/AiInterview.scss'
import { Row,Col,Spinner,Modal } from 'react-bootstrap'
import moment from 'moment'

const InterviewReport = ({organization, interview, aiInterviewInvite, job, report, reportDetail, candidate}) => {
   const [expandedQuestion, setExpandedQuestion] = useState(null);
   const [videoUrl, setVideoUrl] = useState('');
   const [videoPlayShow, setVideoPlayShow] = useState(false);
   const [scroreData, setScroreData]= useState(JSON.parse(report?.score));
   const videoRef = useRef(null); // Create a ref for the video element
   
    // Reset video when modal is closed
    useEffect(() => {
        if (!videoPlayShow && videoRef.current) {
            videoRef.current.pause(); // Pause the video
            videoRef.current.currentTime = 0; // Reset the video to the start
        }
    }, [videoPlayShow]);

   const toggleAnswer = (index) => {
        if (expandedQuestion === index) {
        setExpandedQuestion(null); // Collapse if the same question is clicked
        } else {
        setExpandedQuestion(index); // Expand if a different question is clicked
        }
   };
    return (
        <>
            <div className="container-fluid ai-containter mb-10">
                <div className="d-flex justify-content-between align-items-center pb-3 mt-5">
                    <div className="d-inline-flex align-items-center">
                        { organization && organization?.image_url && organization?.image_url != null &&
                            <img
                                alt={ organization.name} src={ organization.image_url}
                                width="50" height="50"
                                className="d-inline-block align-top"
                            />
                        }
                        {' '}
                        <div className="ml-2">
                            <p className="mb-n1">{ organization.name}</p>
                            <a href={ organization.website_url} target='_blank' className="text-decoration-underline">{ organization.website_url }</a>
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg p-4 shadow">
                    
                    <div className="d-flex justify-content-between align-items-center">
                        <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb breadcrumb-ai">
                                <li className="breadcrumb-item"><a href="/ai_interviews/create">AI Interviews</a></li>
                                <li className="breadcrumb-item"> <a href={`/ai_interviews/${interview.id}/detail`}>{ interview.interview_title }</a></li>
                                <li className="breadcrumb-item"> <a href={`/ai_interviews/${interview.id}/invites`}>Invited Candidates</a></li>
                                <li className="breadcrumb-item active" aria-current="page"> Report</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right  pt-5 pl-4">
                        <div><h4 style={{textTransform:'uppercase',fontWeight: '700'}}>{ interview.interview_title}</h4></div>
                    </div>
                </div>
                <Row>
                    <Col xl={6} md={6} lg={6} sm={12}> 
                        <div className="justify-content-between align-items-right pt-2">
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Name: </label>
                                <p className='pl-2'>{ aiInterviewInvite.candidate_name }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Email: </label>
                                <p className='pl-2'>{ aiInterviewInvite.candidate_email }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Phone Number: </label>
                                <p className='pl-2'>{ (aiInterviewInvite.phone_num != null && aiInterviewInvite.phone_num != 'null') ? aiInterviewInvite.phone_num : '-' }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Resume: </label>
                                <p className='pl-2'>{ (candidate != null && candidate?.cv_url !=  null && candidate?.cv_url !=  '') ? <a href={candidate?.cv_url} target='_blank' className='ml-1' title='Resume'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                        <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg>
                      </a> : 'Not available' }</p>
                            </div>

                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Interview Taken At:  </label>
                                <p className='pl-2'>{ (report.int_start_at != null) ? moment(report.int_start_at).format('MMM DD, YYYY h:mm A')  : '-' }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}> Interview Completed At: </label>
                                <p className='pl-2'>{ (report.int_end_at != null) ? moment(report.int_end_at).format('MMM DD, YYYY h:mm A')  : '-'}</p>
                            </div>
                        </div>
                        
                        <div className="justify-content-between align-items-right">
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}>Job Title: </label>
                                <p className='pl-2'><a href={`/jobs/${ job.id}`}>{ job.name }</a></p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}>Interview Type: </label>
                                <p className='pl-2'>{ interview.interview_type }</p>
                            </div>
                            <div className="d-flex align-items-right pt-1 pl-4">
                                <label className='mb-0' style={{ minWidth: '195px',fontWeight: '600' }}>Skill: </label>
                                { ((job.skills).split(',')).map((value) =>{
                                        return <span key={value} className='skill-bubble-custom mr-1'>{value}</span>
                                    })}
                                    { interview.addition_skill != '' && interview.addition_skill != null &&
                                    <>
                                        { ((interview.addition_skill).split(',')).map((value) =>{
                                            return <span key={value} className='skill-bubble-custom mr-1'>{value}</span>
                                        })}
                                    </>
                                    }
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} md={6} lg={6} sm={12}> 
                            <div className='scrore-header-report'>
                                <p size="16px" height="27px" className='p-score-report'>
                                    Score
                                </p>
                            </div>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Accuracy:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_accuracy}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Coherence:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_coherence}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Application:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_application}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                             Sentiment:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_sentiment}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Technical Depth:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {scroreData?.average_technical_depth}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} sm={6} lg={3} className='pr-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report' style={{fontWeight: '600' }}>
                                            Ratting:
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={3} md={3} sm={6} lg={3}  className='pl-0'>
                                    <div className='score-body-report'>
                                        <p className='score-body-p-report'>
                                            {report?.rating}
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                    </Col>
                </Row>
                <hr></hr>
                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right  pt-5 pl-4">
                        <div><h4 style={{fontWeight: '700'}}>RESULT SUMMARY</h4></div>
                    </div>
                </div>
                <div className="justify-content-between align-items-right mt-4">
                    <div className="d-flex align-items-right flex-column pt-1 pl-5 mt-2">
                        <>
                        <label className='mb-2' style={{ fontWeight: 600, fontSize: '15px' }}>Questions / Answers</label>
                            {reportDetail.map((value, index) => (
                                <div key={index} className='answer-box'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <span className='pt-2' style={{fontWeight: '510'}}>
                                        {index + 1}.{'  '}{value.question}
                                        </span>
                                        
                                    </div>
                                    <div className="mt-3 mb-2 ml-3 border rounded-lg p-2 shadow rating-main-box">
                                            <span className="text-sm font-medium"><b>AI rating :</b> <label className='pl-2 rating-sub-box mb-0'>{value.rating}</label></span>
                                    </div>
                                    <div className="pl-3" style={{ color: 'rgb(85 85 94/1)'}}>{value.ai_feedback}</div>
                                    <div className='pl-3'>
                                    {/*<div className='d-flex justify-content-between align-items-center'>
                                        <span className='pt-2' style={{fontSize: '15px', fontWeight: '550px'}}>Answer</span>
                                         <span
                                            className={`question-arrow ${expandedQuestion === index ? 'question-rotate' : ''}`}
                                            onClick={() => toggleAnswer(index)}
                                            style={{ cursor: 'pointer' }}
                                            >
                                            ^
                                        </span> 
                                    </div>*/}
                                    {/* {expandedQuestion === index && ( */}
                                        <div className='border rounded-lg p-3 shadow mt-3 mb-2' style={{background: '#fff'}}>
                                            <div className='pt-2'>
                                                <div className="recored-play-btn rounded-full bg-blue grid place-items-center" onClick={(()=>(setVideoUrl(value.video_url),setVideoPlayShow(true)))}><svg width="17" height="17" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: '6px'}}><path d="M22.5 11.4019C24.5 12.5566 24.5 15.4434 22.5 16.5981L4.49999 26.9904C2.49999 28.1451 -1.34201e-06 26.7017 -1.24107e-06 24.3923L-3.32543e-07 3.60769C-2.31596e-07 1.29829 2.5 -0.145083 4.5 1.00962L22.5 11.4019Z" fill="#3444DA"></path></svg> View recording of this portion</div>
                                            </div>
                                            <p className='pl-2 pt-3 capitalize-first-word'>{value.transcript_text}</p>
                                        </div>
                                    {/* )} */}
                                    </div>
                                </div>
                            ))}
                        </>
                    </div>
                </div>

            </div>
            <Modal
                show={videoPlayShow}
                onHide={() => setVideoPlayShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Body>
                    <div>
                        <div className="d-flex justify-content-between">
                            <h4 className="font-weight-bold mb-3">Video</h4>

                            <span onClick={() => setVideoPlayShow(false)} role="button">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '30px', height: '30px' }}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                                <video ref={videoRef} className="embed-responsive-item" controls style={{height: 'auto',width: '100%'}}>
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
      );

}
export default InterviewReport