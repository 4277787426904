import React, { useState, useEffect } from 'react'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import '../styles/AiInterview.scss'
import SignUpFields from './SignUpFields'
import { makeRequest } from "../../../common/RequestAssist/RequestAssist";
import { capitalize } from '../../../../utils'
import GoogleIcon from '../../../../../assets/images/icons/icon-google.svg'
import { GoogleButton } from '../../SignupPage/styles/Signup.styled'

const SignUpTalent = ({token,candidateInfo}) => {
    const [selectedRole, setSelectedRole] = useState('talent');
    
    const handleGoogleSignIn = () => {
        window.location.href = `/user/auth/google_oauth2?role=${selectedRole}`
    }
    
    return (
        <section className={`container d-flex justify-content-center align-items-center mb-80`}>
            <Row className="d-flex w-50 shadow-lg">
                <Col className="px-5 py-4">
                    <h5 className="font-weight-bold mb-3 text-left">Create A {capitalize(selectedRole)} Account</h5>
                    <h4 className="mb-3" style={{fontSize: '32px',lineHeight: '118%', display: 'flex',alignItems: 'center',color: '#005999'}}>
                        Discover Your Path with CardinalTalent.AI
                    </h4>
                    <div className="mb-3">
                      <SignUpFields userRole={'talent'} token={token} candidateDetails={candidateInfo} />

                      <div className="d-flex align-items-center text-muted text-center my-2">
                        <span className="flex-grow-1 border-top"></span>
                        <span className="px-2">or</span>
                        <span className="flex-grow-1 border-top"></span>
                      </div>

                      <GoogleButton
                          onClick={() => handleGoogleSignIn()}
                        >
                        <img src={GoogleIcon} />
                        Sign up with Google
                      </GoogleButton>
                    </div>

                    {/* <p className="text-center mt-3 font-size-4">Already have an account? <a href="/users/sign_in">Sign In</a></p> */}
                </Col>
            </Row >
        </section>
    );

}
export default SignUpTalent